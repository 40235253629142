import React from 'react';
import SEO from '../components/seo';
import Layout from '../layouts/main';

const Thankyou = () => {
    return (
        <Layout>
            <SEO title="Thankyou"/>
            <div className="section contactus">
                <div className="innerwrap">
                    <div className="row">
                        <div className="grid-8 aligncenter thankyou">
                            <div className="section-title">
                                {/* <span>Lets Connect</span> */}
                                <h2>Thankyou for Registration</h2>
                                <p>We've received your registration details and we will get back to you when courses start</p>
                                <p>In the mean time make sure to follow us on <a href="https://www.facebook.com/savnetit" target="_blank">Facebook</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Thankyou;